import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { oo } from "../theme"; // light, dark

const useStyles = makeStyles({
  grey: {
    color: oo.grey3,
  },
  lightGrey: {
    color: oo.grey7,
  },
});

export const OoTypoGrey = (props) => {
  const { children, ...other } = props; // eslint-disable-line react/prop-types
  const classes = useStyles();
  return (
    <Typography {...other} className={classes.grey}>
      {children}
    </Typography>
  );
};
