import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import OoAvatar from "../element/graphical/OoAvatar";
import thomasAvatar from "../../res/avatar/team-thomas-uta-avatar-170px-OberionIO.jpg";
import oezkanAvatar from "../../res/avatar/team-oezkan-isik-avatar-170px-OberionIO.jpg";
import danielAvatar from "../../res/avatar/team-daniel-palke-avatar-170px-OberionIO.jpg";
import maxAvatar from "../../res/avatar/team-maximilian-weber-avatar-170px-OberionIO.jpg";
import { oo } from "../element/theme";
import { A_REL, OutboundLinkThemed } from "../navigation/ExternalLink";


const TeamSection = styled.section`
  margin-top: ${oo.verticalSpacing}rem;
  width: 100%;
  padding: 0 0.5rem;
`;
const teamData = [
  {
    name: "Thomas Uta",
    jobTitle: ["Founder", "Full Stack Developer"],
    imgSrc: thomasAvatar,
    links: [
      ["Portfolio", "https://thomasuta.com/"],
      ["Twitter", "https://twitter.com/ThomasJanUta/"],
    ],
  }, {
    name: "Özkan Isik",
    jobTitle: ["Design"],
    imgSrc: oezkanAvatar,
    links: [["Portfolio", "https://www.isiko.de/"]],
  }, {
    name: "Daniel Palke",
    jobTitle: ["Marketing"],
    imgSrc: danielAvatar,
    links: [["XING", "https://www.xing.com/profile/Daniel_Palke/"]],
  }, {
    name: "Maximilian Weber",
    jobTitle: ["Industry Advisor"],
    imgSrc: maxAvatar,
    links: [
      ["Company", "https://bsidegames.com/"],
      ["Twitter", "https://twitter.com/CineTekDev/"],
    ],
  },
];


const useStyles = makeStyles({
  gridContainer: {
    margin: "3rem 0",
  },
  paper: {
    margin: "0 -0.5rem",
    padding: "2rem",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
  },
});

const Team = () => {
  const classes = useStyles();
  return (
    <TeamSection>
      <Typography variant="h3">Team</Typography>
      <Grid container spacing={2} alignItems="stretch"
            className={classes.gridContainer}>
        {teamData.map((el, index) => (
          <Grid item xs={12} sm={6} key={teamData[index].name}>

            <Paper className={classes.paper}>
              <OoAvatar src={teamData[index].imgSrc} alt={teamData[index].name} />
              <Typography component="p" variant="h5" paragraph>{teamData[index].name}</Typography>
              <Typography component="p" variant="body2" gutterBottom>
                {teamData[index].jobTitle[0]}
              </Typography>

              {teamData[index].jobTitle[1] &&
              <Typography component="p" variant="body2" gutterBottom>
                {teamData[index].jobTitle[1]}
              </Typography>}

              {teamData[index].links.map(el => (
                <OutboundLinkThemed caption={el[0]} href={el[1]} rel={A_REL.ENDORSE}
                                    variant="body1" lightTheme
                                    key={el[0]} />
              ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </TeamSection>
  );
};
export default Team;