import React from "react";
import Layout from "../components/element/layout/Layout";
import LayoutMain from "../components/element/layout/LayoutMain";
import PageBanner from "../components/element/page-element/PageBanner";
import { oo } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
import Team from "../components/about/Team";
import Features from "../components/about/Features";
import SocialMediaTable from "../components/element/social-media/SocialMediaTable";
import routes from "../components/navigation/routes";
import { Typography } from "@material-ui/core";

const seo = {
  title: "About - Oberion",
  description: "About Oberion",
  canonical: routes.baseUrl + routes.about.to,
};
const aboutHeading = "About Oberion";
const aboutTagline = "The Website Builder for Game Developers";
const followMeHeading = "What do you think? Tell us more on:";

const About = () => (
  <Layout showNewsletter>
    <SEO {...seo} />
    <PageBanner heading={aboutHeading} subheading={aboutTagline} />
    <LayoutMain>
      <LayoutColumn maxWidth={oo.textWidth} alignItems="flex-start">

        <Features />
        <Team />
        <Typography component="h3" variant="h6">{followMeHeading}</Typography>
        <SocialMediaTable />

      </LayoutColumn>
    </LayoutMain>
  </Layout>
);
export default About;
