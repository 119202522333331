import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { oo } from "../element/theme";
import { OoTypoGrey } from "../element/page-element/OoTypo";
import shieldAndSword from "../../res/icon-oberion/shield-and-sword.min.svg";
import powerfulSearch
  from "../../res/illustration/user-story-knowledge-gaming-market-v2-OberionIO.min.svg";
import curatedStorefront
  from "../../res/illustration/user-story-curated-storefront-v2-OberionIO.min.svg";
import nicheGames
  from "../../res/illustration/user-story-find-assess-niche-games-v2-OberionIO.min.svg";

const FeaturesSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
const TopFeature = styled.div`
  max-width: ${oo.textWidth};
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
const ImageTop = styled.img`
  height: 5rem;
  margin-bottom: 0.5rem;
`;
const ImageFeatures = styled.img`
  width: 100%;
  max-width: ${oo.textWidth / 2}rem;
`;
const GridContainer = styled(Grid)`
  padding-top: 4rem;
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const topFeaturetxt = [
  "Oberion creates beautiful websites so you can focus on what you do best",
  "Making a great game",
  "Oberion is in early access. Come along with us to help game devs publish their games.",
];

const headings = [
  "Focus on developing",
  "Affordable Pricing",
  "Choose Your Template",
];
const text = [
  "It's not necessary to spend a lot of time constructing an awesome website. We use data from store pages such as Steam or itch.io to create the website content for you.",
  "The basics are free. You own the website source code. Host anywhere.",
  "Choose from a variety of templates to give your site the special touch. ",
];

const Features = () => {
  return (
    <FeaturesSection>

      <TopFeature>
        <ImageTop src={shieldAndSword} alt="Shield and Sword" />
        <Typography variant="h6">{topFeaturetxt[0]}</Typography>
        <Typography variant="h6" paragraph>{topFeaturetxt[1]}</Typography>
        <OoTypoGrey variant="subtitle1">{topFeaturetxt[2]}</OoTypoGrey>
      </TopFeature>

      <GridContainer container spacing={0} justify="space-between" align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[0]}</Typography>
          <OoTypoGrey variant="body1">{text[0]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={powerfulSearch} alt="Powerful Search" />
        </Grid>
      </GridContainer>

      <GridContainer container spacing={0} direction="row-reverse" justify="space-between"
                     align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[1]}</Typography>
          <OoTypoGrey variant="body1">{text[1]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={curatedStorefront} alt="Curated Storefront" />
        </Grid>
      </GridContainer>

      <GridContainer container spacing={0} justify="space-between" align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[2]}</Typography>
          <OoTypoGrey variant="body1">{text[2]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={nicheGames} alt="Niche Games" />
        </Grid>
      </GridContainer>

    </FeaturesSection>
  );
};
export default Features;