import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme, { oo } from "../theme";
import { lessOrEqualThan } from "../mediaQuery";

export const StyledTableCss = styled.table`
  margin: 0.5rem auto 0 auto;
  text-align: left;
`;

export const StyledTd = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 0.3rem 0.5rem;
`;
export const StyledTdNoPadding = styled.td`
  border-bottom: 1px solid #ddd;
`;
export const StyledTdNoBorder = styled.td`
  padding: 0 0.5rem;
  min-width: 5rem;
  ${lessOrEqualThan.sm`
    padding: 0 0.3rem;
    min-width: 4rem;
  `};
`;

export const StyledTdGreen = styled(StyledTd)`
  color: ${oo.green1};
  font-weight: 500;
`;

export const StyledTdBlue = styled(StyledTd)`
  color: ${theme.palette.primary.light};
`;
export const StyledTdOrange = styled(StyledTd)`
  color: ${oo.red3};
`;


const StyledTable = (props) => {
  const { jsonContent } = props;
  return (
    <StyledTableCss>
      <tbody>
      {jsonContent.map((element) => (
        <tr key={element.row}>
          <StyledTdNoPadding>{element.month}</StyledTdNoPadding>
          <StyledTdNoPadding>{element.year}</StyledTdNoPadding>
          <StyledTd>{element.description}</StyledTd>
        </tr>
      ))}
      </tbody>
    </StyledTableCss>
  );
};

StyledTable.propTypes = {
  jsonContent: PropTypes.array.isRequired,
};

export default StyledTable;